import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>AWS Serverless hosting of a gatsby site via CICD</h1>
    <p>
      This is a static website hosting CICD test. a Code pipeline initiates a
      build and deployment to test.sandstorm.nl and will coexist next to other
      CodePipelines. Some resources are shared via the Sandstorm InfraStack to
      keep AWS deployment &amp; troubleshooting efficient.
    </p>
    <p>
      Website code is developed in a VDI environment. The VDI environment allows
      local testing and verification. Source code is versioned by pushing it to
      GitHub. AWS CodePipeline will notice an updated Github repo, download it,
      build the static website and deploy its code accordingly.
    </p>
    <p>
      The IAC template takes care of setting up a superfast and secure website
      via CloudFront (CDN) distribution. It also adds SSL Certificates and
      creates/glues domainnames if the zone is available in Route53: e.g.
      example.com and www.example.com. The final work being tested here is to
      integrate it with Gatsby websites being deployed via CICD. It will also
      facilitate an "universal" approach for other websites still taking
      advantage of versioning via Github.
    </p>
    <p>
      Also have a look here:{" "}
      <a href="https://www.kapsalonpink.com" alt="" target="_blank">
        kapsalonpink website
      </a>{" "}
      or{" "}
      <a href="https://www.sandstorm.nl" alt="" target="_blank">
        sandstorm main website
      </a>
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link>
    <div class="test">hello world again</div>
  </Layout>
)

export default IndexPage
